
<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <div class="w-full flex flex-wrap">
      <!-- Image Section -->
      <div class="w-full lg:w-1/2 shadow-2xl">
        <div
          style="transform: translateX(-50%); left: 50%; min-width: 90%"
          class="
            absolute
            mobile-home
            mt-20
            pb-12
            bg-opacity-40
            rounded-lg
            flex flex-col
            justify-center
            bg-white
            pt-8
            md:pt-0
            px-8
            md:px-24
            lg:px-32
            z-10
          "
        >
          <p class="text-left font-bold text-3xl">Forgot Password</p>
          <p class="text-md pt-4">
            Enter your email in the below field, and we will send an email with
            a link to reset your password. If any errors occur, please contact
            our customer support team.
          </p>
          <form
            @submit.prevent="sendCustomerPasswordReset(user)"
            class="flex flex-col pt-3 md:pt-8 z-40"
          >
            <div class="flex flex-col pt-4 z-40">
              <label for="email" class="text-lg">Email</label>
              <input
                v-model="user.contactEmail"
                type="email"
                id="email"
                placeholder="Email"
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-4
                  px-3
                  text-gray-700
                  mt-1
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
              />
            </div>

            <div class="flex mt-8 items-center">
              <input
                style="background-color: #d3b986"
                type="submit"
                value="Reset"
                class="
                  z-40
                  bg-green-400
                  w-1/4
                  text-white
                  rounded
                  font-bold
                  text-lg
                  hover:bg-green-600
                  p-2
                "
              />
              <router-link style="color: #d3b986" class="ml-3" to="/"
                >Login</router-link
              >
            </div>
          </form>
        </div>

        <video
          src="/loginbg3.mp4"
          autoplay
          playsinline
          muted
          loop
          class="object-cover z-10 w-full h-4/6"
          style="height: 95vh; z-index: 1"
        ></video>

        <div class="home absolute bottom-0 pl-2">
          <img class="opacity-80" src="/lilylogo.png" width="180px" />
        </div>

        <div
          class="
            home
            text-white
            opacity-75
            absolute
            text-right
            bottom-0
            p-2
            w-1/2
            sm:text-sm
            text-lg
            lg:text-lg
            sm:mx-60
            lg:mx-auto
          "
        >
          Our Valued Customers
        </div>
      </div>

      <!-- Login Section -->
      <div class="home w-full md:w-1/2 flex flex-col mt-20">
        <div
          class="flex justify-center md:justify-start pt-12 md:pl-12 md:-mb-24"
        ></div>

        <div
          class="
            mt-20
            flex flex-col
            justify-center
            md:justify-start
            my-auto
            pt-8
            md:pt-0
            px-8
            md:px-24
            lg:px-32
            mb-auto
          "
        >
          <p class="text-left font-bold text-3xl">Forgot Password</p>
          <p class="text-md pt-4">
            Enter your email in the below field, and we will send an email with
            a link to reset your password. If any errors occur, please contact
            our customer support team.
          </p>
          <form
            @submit.prevent="sendCustomerPasswordReset(user)"
            class="flex flex-col pt-3 md:pt-8"
            onsubmit="event.preventDefault();"
          >
            <div class="flex flex-col pt-4">
              <label for="email" class="text-lg">Email</label>
              <input
                v-model="user.contactEmail"
                placeholder="Email"
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-4
                  px-3
                  text-gray-700
                  mt-1
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
              />
            </div>
            <div class="flex mt-8 items-center">
              <input
                style="background-color: #d3b986"
                type="submit"
                value="Reset"
                class="
                  cursor-pointer
                  w-1/4
                  text-white
                  rounded
                  font-bold
                  text-lg
                  hover:shadow-lg
                  p-2
                "
              />
              <router-link style="color: #d3b986" class="ml-3" to="/"
                >Login</router-link
              >
            </div>
          </form>
          <div class="home text-xs text-left bottom-0 items-end py-5">
            © Experglobal Trading Company, All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import HeaderLayout from "../layout/Header.Layout.vue";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { mapActions } from "vuex";
export default {
  name: "Login.Page",
  components: {
    HeaderLayout,
  },

  data() {
    return {
      user: {
        contactEmail: "",
      },
      options: {
        type: "loop",
        rewind: true,
        arrows: false,
        width: 900,
        pagination: true,
        isNavigation: false,
        focus: "center",
        perPage: 5,
        gap: "1rem",
      },
    };
  },

  computed: {},
  methods: {
    ...mapActions(["sendCustomerPasswordReset"]),
  },
};
</script>



<style>
@media only screen and (min-width: 768px) {
  .video-wrapper {
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
  }

  .video-wrapper video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .video-title {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }

  .video-desc {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
}

.work-sans {
  font-family: "Work Sans", sans-serif;
}

#menu-toggle:checked + #menu {
  display: block;
}

.hover\:grow {
  transition: all 0.3s;
  transform: scale(1);
}

.hover\:grow:hover {
  transform: scale(1.02);
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
</style>




<style>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mobile-home {
    display: block;
  }

  .home {
    display: none;
  }

  .homeslide {
    margin-top: -50px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .mobile-home {
    display: none;
  }

  .home {
    display: block;
  }

  .homeslide {
    margin-top: -140px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .mobile-home {
    display: none;
  }

  .home {
    display: block;
  }

  .homeslide {
    margin-top: -140px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .mobile-home {
    display: none;
  }

  .home {
    display: block;
  }
  .homeslide {
    margin-top: -10px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .mobile-home {
    display: none;
  }

  .home {
    display: block;
  }
  .homeslide {
    margin-top: -150px;
  }
}
</style>